import { useState } from "react";
import "./MyProfile.scss";
import { mdiPencil } from "@mdi/js";
import { mdiDownload } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useAuth } from "../Context/AuthContext";
import { toast } from "react-toastify";
import Modal from "react-modal";
import EditPersonalDetails, {
  EditPersonal,
} from "../EditDetails/EditPersonalDetails";
import EditCompanyDetails from "../EditDetails/EditCompanyDetails";
import { UpdatePersonalDetails } from "../Api/PutApi";
import { catchError } from "../utils/catchError";
import { FormikHelpers } from "formik";
import {
  findOrganizationLabelByValue,
  isPublisher,
} from "../../utils/services";

export const ProfileDetails = () => {
  // for toggling of edit personal modal
  const [isEPOpen, setIsEPOpen] = useState(false);
  // For toggling the company details modal
  const [isCDOpen, setIsCDOpen] = useState(false);
  const { userData, userProfile, role } = useAuth();
  const toggleCDModal = () => {
    setIsCDOpen(!isCDOpen);
  };

  const toggleEPModal = () => {
    setIsEPOpen(!isEPOpen);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "315px",
      width: "50%",
    },
  };

  const customClass = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "600px",
      width: "60%",
      zIndex: 50,
    },
  };

  const editPersonalDetail = (
    data: EditPersonal,
    { setSubmitting }: FormikHelpers<EditPersonal>
  ) => {
    UpdatePersonalDetails(data)
      .then((res) => {
        userProfile();
        toast.success(res.data.status.status_message);
        setIsEPOpen(false);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="profile-info">
      {/* Edit personal Modal */}
      <Modal
        isOpen={isEPOpen}
        onRequestClose={toggleEPModal}
        contentLabel="My dialog"
        style={customStyles}
        ariaHideApp={false}
      >
        <EditPersonalDetails
          toggleModal={() => toggleEPModal()}
          userData={userData}
          onSubmit={editPersonalDetail}
        />
      </Modal>
      {/* Edit Company Detail  */}
      <Modal
        isOpen={isCDOpen}
        onRequestClose={toggleCDModal}
        contentLabel="My dialog"
        style={customClass}
        ariaHideApp={false}
      >
        <EditCompanyDetails
          toggleModal={() => toggleCDModal()}
          userData={userData}
        />
      </Modal>

      <>
        <div className="detail-content">
          <fieldset className="border p-2">
            <legend className="w-auto">
              <div className="Personal-details-topic topic ">
                Edit Personal Details
              </div>
            </legend>

            <div className="head-line"></div>
            <div className="row">
              <div className="col-md-4">
                <div className="individual-details">
                  <table className="table borderless">
                    <tr>
                      <td className="width-25">
                        <b>First Name:</b>
                      </td>
                      <td className="width-45">{userData?.first_name}</td>
                    </tr>
                    <tr>
                      <td className="width-25">
                        <b>Email:</b>
                      </td>
                      <td className="width-45">{userData?.email}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-3">
                <div className="individual-details">
                  <table className="table borderless">
                    <tr>
                      <td className="width-25">
                        <b>Middle Name:</b>
                      </td>
                      <td className="width-25">{userData?.middle_name}</td>
                    </tr>

                    <td className="width-45">
                      <b>Contact No:</b>
                    </td>
                    <td className="width-45">{`+${userData?.contact_no}`}</td>
                  </table>
                </div>
              </div>
              <div className="col-md-4">
                <div className="individual-details">
                  <table className="table borderless">
                    <tr>
                      <td className="width-25">
                        <b>Last name:</b>
                      </td>
                      <td className="width-45">{userData?.last_name}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-md-1">
                <div className="change-password  change-pedit-width ">
                  <div className="Personal-details-topic">
                    <button
                      className="btn-name btn-edit btn-edit-icon"
                      onClick={toggleEPModal}
                    >
                      <div className="d-flex">
                        <div className="icon-size ">
                          <Icon path={mdiPencil} />
                        </div>
                        Edit
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </>

      <div className="detail-content">
        <fieldset className="border p-2">
          <legend className="w-auto">
            <div className="Personal-details-topic topic">
              Organization Details
            </div>
          </legend>
          <div className="head-line"></div>

          <div className="row">
            <div className="col-md-12">
              <div className="Personal-details-topic change-password organization-detail-edit-button change-pass-lgwidth editorg-icon ">
                <button
                  className="btn-name  btn-edit btn-edit-icon"
                  onClick={toggleCDModal}
                >
                  <div className="d-flex">
                    <div className="icon-size">
                      <Icon path={mdiPencil} />
                    </div>
                    <p className="edit-org-details"> Edit </p>
                  </div>
                </button>
              </div>
            </div>
            {isPublisher(role) && (
              <>
                <div className="col-md-2 mb-3">
                  <img
                    src={userData?.logo}
                    alt="logo"
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "1px solid #ddd",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="col-md-8">
                  <p className="mb-2">
                    <b>About Company:</b>
                  </p>
                  <div className="d-flex mb-2">
                    <p>{userData?.introduction}</p>
                  </div>
                  <div className="d-flex mb-2">
                    <p className="mr-2">
                      <b>Established Date:</b>
                    </p>
                    <p>{userData?.estd}</p>
                    <p className=" ml-4 mr-2">
                      <b>Number of Employees:</b>
                    </p>
                    <p>{userData?.employee_no}</p>
                  </div>
                </div>
              </>
            )}
            <div className="col-md-5">
              <div className="last-data">
                <div className="org-table">
                  <table className="table">
                    <tr>
                      <td className="width-45">
                        <b>Company Name:</b>
                      </td>
                      <td className="width-45">{userData?.company_name}</td>
                    </tr>
                    <tr>
                      <td className="width-45">
                        <b>Official Email:</b>
                      </td>
                      <td className="width-45">
                        {userData?.office_email || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="width-45">
                        <b>Company Contact No:</b>
                      </td>
                      <td className="width-45">
                        {userData?.company_contact_no || "N/A"}
                      </td>
                    </tr>

                    <tr>
                      <td className="width-45">
                        <b>District:</b>
                      </td>
                      <td className="width-45">{userData?.district}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="table-org">
                <div className="table">
                  <table>
                    <tr>
                      <td className="width-45">
                        <b>Organization Category:</b>
                      </td>
                      <td className="width-45">
                        {findOrganizationLabelByValue(
                          userData?.organization_category
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="width-45">
                        <b>Province:</b>
                      </td>

                      <td className="width-45">{userData?.province}</td>
                    </tr>
                    <tr>
                      <td className="width-45">
                        <b>Municipality:</b>
                      </td>
                      <td className="width-45">{userData?.municipality}</td>
                    </tr>
                    <tr>
                      <td className="width-45">
                        <b>Website URL:</b>
                      </td>
                      <td className="width-45">
                        <a
                          className="d-flex"
                          href={userData?.website_url || "#"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {userData?.website_url || "N/A"}
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="border p-2 mt-4">
          <legend className="w-auto">
            <div className="Personal-details-topic topic">Certificates</div>
          </legend>
          <div className="row">
            <div className="col-md-6">
              <tr>
                <td className="app-letter width-45">
                  <b>Company Registration Certificate Front:</b>
                </td>

                <div className="d-flex gap-2 certificate-content">
                  {userData?.registration_certificate_front === "" ||
                  userData?.registration_certificate_front === null ? (
                    <div className="text-danger">No file</div>
                  ) : (
                    <div className="download-wrapper">
                      <a
                        className="d-flex"
                        href={userData?.registration_certificate_front}
                      >
                        <div className="icon-size ">
                          <Icon path={mdiDownload} />
                        </div>
                        <span>Download</span>
                      </a>
                    </div>
                  )}
                </div>
              </tr>
              <tr>
                <td className="app-letter width-45">
                  <b>Pan/Vat Certificate:</b>
                </td>

                <div className="d-flex gap-2 certificate-content">
                  {userData?.pan_vat_certificate === "" ||
                  userData?.pan_vat_certificate === null ? (
                    <div className="text-danger">No file</div>
                  ) : (
                    <div className="download-wrapper">
                      <a
                        className="d-flex"
                        href={userData?.pan_vat_certificate}
                      >
                        <div className="icon-size ">
                          <Icon path={mdiDownload} />
                        </div>
                        <span>Download</span>
                      </a>
                    </div>
                  )}
                </div>
              </tr>
              {userData?.liscense_back && (
                <tr>
                  <td className="app-letter width-45">
                    <b>Liscense Back:</b>
                  </td>

                  <div className="d-flex gap-2 certificate-content">
                    <div className="download-wrapper">
                      <a className="d-flex" href={userData?.liscense_back}>
                        <div className="icon-size ">
                          <Icon path={mdiDownload} />
                        </div>
                        <span>Download</span>
                      </a>
                    </div>
                  </div>
                </tr>
              )}
            </div>
            <div className="col-md-6">
              <tr>
                <td className="app-letter width-45">
                  <b>Company Registration Certificate Back:</b>
                </td>
                {userData?.registration_certificate_back === "" ||
                userData?.registration_certificate_back === null ? (
                  <div className="text-danger mg-left-25">No file</div>
                ) : (
                  <div className="download-wrapper">
                    <a
                      className="d-flex"
                      href={userData?.registration_certificate_back}
                    >
                      <div className="icon-size ">
                        <Icon path={mdiDownload} />
                      </div>
                      <span>Download</span>
                    </a>
                  </div>
                )}
              </tr>
              {userData?.liscense_front && (
                <tr>
                  <td className="app-letter width-45">
                    <b>Liscense Front:</b>
                  </td>

                  <div className="d-flex gap-2 certificate-content">
                    <div className="download-wrapper">
                      <a className="d-flex" href={userData?.liscense_front}>
                        <div className="icon-size">
                          <Icon path={mdiDownload} />
                        </div>
                        <span>Download</span>
                      </a>
                    </div>
                  </div>
                </tr>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};
