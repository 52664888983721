import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { ResultShimmer } from "../../Pages/Results/ResultShimmer";
import { useGlobal } from "../Context/GlobalContext";
import { useSearch } from "../Context/SearchContext";
import { NoDataFound } from "../NoDataFound/NoDataFound";
import usePagination from "../Pagination/usePagination";
import { TenderCard } from "../TenderCard/TenderCard";
import "../TenderCard/TenderCard.scss";
import { TenderCardAd } from "../TenderCard/TenderCardAd";
import { ResponseTenderCard } from "../TenderCard/TenderCardGroup";
import { checkDay } from "../utils/DateConversion";

export interface NewTenderCardProps {
  date?: string;
  data?: Array<ResponseTenderCard>;
}

export interface EbidTenderCardGroupProps {
  tenderCard?: any;
  totalTender: number;
  pageSize: number;
  loading: boolean;
}

export const EBidPage: React.FC<EbidTenderCardGroupProps> = ({
  tenderCard, 
  totalTender,
  pageSize,
  loading,
}) => {
  const { advertisements } = useGlobal();
  const { page, setPage } = useSearch();
  const [ignore, setIgnore] = useState<boolean>(false);
  var adIndex = 0;

  const handleChange = (e: any, p: any) => {
    window.scrollTo({ top: 0 });
    setPage(p);
    _DATA.jump(p);
  };

  const count: number = Math.ceil(Number(totalTender) / Number(pageSize));
  const _DATA = usePagination(tenderCard ||[], pageSize);

  return (
    <div className="cardwrapper-section">
      <div>
        {loading && !tenderCard ? (
          <ResultShimmer />
        ) : (
          <>
            {tenderCard && tenderCard.length > 0 ? (
              tenderCard.map((item: NewTenderCardProps, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <h4 className="time-date">{checkDay(item.date)}</h4>
                    <div className="">
                      {(item.data as ResponseTenderCard[]).map(
                        (val: ResponseTenderCard, id: number) => {
                          return (
                            <>
                              <React.Fragment key={id}>
                                <TenderCard
                                  key={id}
                                  ignore={ignore}
                                  setIgnore={setIgnore}
                                  savebids={false}
                                  dataValue={val}
                                />

                                {page === 1 &&
                                  (id + 1) % 3 === 0 &&
                                  advertisements &&
                                  advertisements["In-Between-Notice"]?.[
                                    adIndex
                                  ] && (
                                    <div className="notice-in-between-card">
                                      <TenderCardAd
                                        advertisement={
                                          advertisements &&
                                          advertisements["In-Between-Notice"]?.[
                                            adIndex
                                          ]
                                        }
                                      />
                                      <div style={{ display: "none" }}>
                                        {adIndex++}
                                      </div>
                                    </div>
                                  )}
                              </React.Fragment>
                            </>
                          );
                        }
                      )}
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div>
                <NoDataFound
                  text1="No tenders found"
                  img={require("../../images/nodata.png")}
                />
              </div>
            )}
            {tenderCard && tenderCard?.length > 0 && (
              <Pagination
                count={count}
                size="large"
                page={page}
                shape="rounded"
                onChange={handleChange}
                siblingCount={0}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
