import { mdiClose } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Pagination } from "@mui/material";
import React, { useContext, useState } from "react";
import usePagination from "../../Components/Pagination/usePagination";
import { ResultShimmer } from "../../Pages/Results/ResultShimmer";
import { useAuth } from "../Context/AuthContext";
import { useGlobal } from "../Context/GlobalContext";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { useSearch } from "../Context/SearchContext";
import { AdditionalAttributes } from "../FormikWrapper/TenderFormikWrapper";
import { ImageSlider } from "../ImageSlider/ImageSlider";
import { NoDataFound } from "../NoDataFound/NoDataFound";
import { checkDay } from "../utils/DateConversion";
import { IUserInfo, ResponseBoq } from "../utils/interface";
import { ProcurementType, TenderCard } from "./TenderCard";
import "./TenderCard.scss";
import { TenderCardAd } from "./TenderCardAd";

export interface NewTenderCardProps {
  date?: string;
  data?: Array<ResponseTenderCard>;
}

export interface ResponseTenderCard {
  id: number;
  technical_pass: null | boolean;
  tender_number: string;
  extra_info: {
    joint_venture_name: string;
    e_payment: string;
  };
  notice_category: string;
  procurement_type: Array<ProcurementType>;
  project_category: Array<ProcurementType>;
  title: string;
  district: string;
  publishing_date: string;
  submission_date: string;
  boq_sum: number;
  amendment: boolean;
  bid_bond_security_amount: string;
  bid_bond_validity: number;
  document_needed: string;
  bank_name: string;
  branch: string;
  account_number: string;
  account_name: string;
  user: number;
  remaining_days: number;
  boq: ResponseBoq;
  award_result: any;
  public_entity_name: string;
  rejection_reason: string;
  deposit_paid: boolean;
  category: string;
  source: string;
  private: boolean;
  image?: string;
  ppmo_file_url?: string;
  notes?: string;
  terms?: string;
  show_boq_item_rate?: boolean;
  category_type?: string;
  opening_date?: string;
  publisher_bid_fee?: number;
  system_bid_fee?: number;
  additional_attributes: AdditionalAttributes[];
  is_main: boolean;
  saved: boolean;
  published: boolean;
  specification_terms_format: string;
  user_info: IUserInfo;
}

export type CardView = "list" | "image";
export interface TenderCardGroupProps {
  viewType: CardView;
  tenderCard?: any;
  loading: boolean;
  totalTender: number;
  pageSize: number;
}
export const TenderCardGroup: React.FC<TenderCardGroupProps> = ({
  viewType,
  tenderCard,
  loading,
  totalTender,
  pageSize,
}) => {
  const { advertisements } = useGlobal();
  const { page, setPage } = useSearch();
  const [ignore, setIgnore] = useState<boolean>(false);
  const [showView, setShowView] = useState("");
  const { isAuthenticate } = useAuth();
  const { TogglePopUpFlags } = useContext(ModalContext);
  const [selectedId, setSelectedId] = useState(0);
  const [openSlider, setOpenSlider] = useState(false);
  var adIndex = 0;

  const handleChange = (e: any, p: any) => {
    window.scrollTo({ top: 0 });
    setPage(p);
    _DATA.jump(p);
  };

  const handleOnClick = (val: any) => {
    const tender_id = val.id;
    if (isAuthenticate === true) {
      toggleImageModal();
      setSelectedId(tender_id);
    } else {
      TogglePopUpFlags("login");
    }
  };

  const getView = (value: any) => {
    setShowView(value);
  };

  function toggleImageModal() {
    setOpenSlider(!openSlider);
  }

  const count: number = Math.ceil(Number(totalTender) / Number(pageSize));
  const _DATA = usePagination(tenderCard || [], pageSize);

  const onhandleClose = () => {
    setOpenSlider(!openSlider);
    setShowView("image");
  };

  return (
    <div className="cardwrapper-section">
      {openSlider && (
        <>
          <div className="slide-wrapper">
            <div onClick={onhandleClose}>
              <Icon className=" mdi-close" path={mdiClose} />
            </div>
            <ImageSlider tenderCard={tenderCard} selectedId={selectedId} />
          </div>
        </>
      )}

      <div>
        {loading ? (
          <ResultShimmer />
        ) : (
          <>
            {tenderCard && tenderCard?.length > 0 && !loading ? (
              tenderCard.map((item: NewTenderCardProps, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <h4 className="time-date">{checkDay(item.date)}</h4>
                    <div className={viewType === "image" ? "gallery-view" : ""}>
                      {(item.data as ResponseTenderCard[]).map(
                        (val: ResponseTenderCard, id: number) => {
                          return (
                            <>
                              {viewType === "list" ? (
                                <React.Fragment key={id}>
                                  <TenderCard
                                    key={id}
                                    ignore={ignore}
                                    setIgnore={setIgnore}
                                    savebids={false}
                                    dataValue={val}
                                  />

                                  {page === 1 &&
                                    (id + 1) % 3 === 0 &&
                                    advertisements &&
                                    advertisements["In-Between-Notice"]?.[
                                      adIndex
                                    ] && (
                                      <div className="notice-in-between-card">
                                        <TenderCardAd
                                          advertisement={
                                            advertisements &&
                                            advertisements[
                                              "In-Between-Notice"
                                            ]?.[adIndex]
                                          }
                                        />
                                        <div style={{ display: "none" }}>
                                          {adIndex++}
                                        </div>
                                      </div>
                                    )}
                                </React.Fragment>
                              ) : (
                                val.image && (
                                  <div
                                    onClick={() => {
                                      handleOnClick(val);
                                    }}
                                  >
                                    {isAuthenticate ? (
                                      <img src={val.image} alt="Notice-img" />
                                    ) : (
                                      <img
                                        src={require("../../images/notice.png")}
                                        alt="Notice-img"
                                      />
                                    )}
                                  </div>
                                )
                              )}
                            </>
                          );
                        }
                      )}
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div>
                <NoDataFound
                  text1="No tenders found"
                  img={require("../../images/nodata.png")}
                />
              </div>
            )}
            {tenderCard && tenderCard?.length > 0 && (
              <Pagination
                count={count}
                size="large"
                page={page}
                shape="rounded"
                onChange={handleChange}
                siblingCount={0}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
